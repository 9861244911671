// React
import React from "react";
import { useNavigate } from "react-router-dom";
import { Box, Typography, Grid, Paper } from "@mui/material";
import styled from "styled-components";
import CurrencyExchangeTwoToneIcon from "@mui/icons-material/CurrencyExchangeTwoTone";
import DiscountTwoToneIcon from "@mui/icons-material/DiscountTwoTone";
import MonetizationOnOutlinedIcon from "@mui/icons-material/MonetizationOnOutlined";
import BusinessTwoToneIcon from "@mui/icons-material/BusinessTwoTone";
import PersonOutlineTwoToneIcon from "@mui/icons-material/PersonOutlineTwoTone";
import BadgeTwoToneIcon from "@mui/icons-material/BadgeTwoTone";
import { useAuth } from "../../services/auth";

const ReportsList = () => {
  let navigate = useNavigate();
  const { role, adminOn } = useAuth();

  let reportAllPermission = [
    {
      name: "Transactions Report",
      icon: (
        <CurrencyExchangeTwoToneIcon
          color="action"
          fontSize="large"
        ></CurrencyExchangeTwoToneIcon>
      ),
      route: "Transactions",
    },
    {
      name: "Transactions By Service",
      icon: (
        <CurrencyExchangeTwoToneIcon
          color="action"
          fontSize="large"
        ></CurrencyExchangeTwoToneIcon>
      ),
      route: "TransactionsByService",
    },
    {
      name: "Void Transactions Report",
      icon: (
        <CurrencyExchangeTwoToneIcon
          color="action"
          fontSize="large"
        ></CurrencyExchangeTwoToneIcon>
      ),
      route: "Voids",
    },
    {
      name: "Shift Report",
      icon: (
        <DiscountTwoToneIcon
          color="action"
          fontSize="large"
        ></DiscountTwoToneIcon>
      ),
      route: "Shift",
    },
  ];
  let reports = [
    {
      name: "Employees Roles By Site Report",
      icon: (
        <PersonOutlineTwoToneIcon
          color="action"
          fontSize="large"
        ></PersonOutlineTwoToneIcon>
      ),
      route: "EmployeesRoles",
    },
    {
      name: "Daily Sales Payroll Report",
      icon: (
        <BadgeTwoToneIcon color="action" fontSize="large"></BadgeTwoToneIcon>
      ),
      route: "DailySales",
    },
    {
      name: "Less Payroll Report",
      icon: (
        <MonetizationOnOutlinedIcon
          color="action"
          fontSize="large"
        ></MonetizationOnOutlinedIcon>
      ),
      route: "LessPayroll",
    },
    {
      name: "Highest Payroll Report",
      icon: (
        <MonetizationOnOutlinedIcon
          color="action"
          fontSize="large"
        ></MonetizationOnOutlinedIcon>
      ),
      route: "HighestPayroll",
    },

    {
      name: "Sites By Sales Report",
      icon: (
        <BusinessTwoToneIcon
          color="action"
          fontSize="large"
        ></BusinessTwoToneIcon>
      ),
      route: "SiteBySales",
    },
    {
      name: "Sites By Tickets Report",
      icon: (
        <BusinessTwoToneIcon
          color="action"
          fontSize="large"
        ></BusinessTwoToneIcon>
      ),
      route: "SitesByTickets",
    },
  ];
  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: "#F7F9FB",
    padding: 5,
    textAlign: "center",
    color: "#1C1C1C",
  }));

  return (
    <>
      <Typography variant="Poster" component="h4">
        Reports
      </Typography>
      <Box sx={{ flexGrow: 1, mt: 2 }}>
        <Grid
          container
          spacing={{ xs: 2, md: 10 }}
          columns={{ xs: 4, sm: 8, md: 12 }}
        >
          {reportAllPermission.map((report, index) => (
            <Grid item xs={2} sm={6} md={6} key={index}>
              <Item>
                <Box sx={{ flexGrow: 1, display: "flex" }}>
                  <Box
                    sx={{
                      flex: "1",
                      mt: 1,
                      mr: 2,
                      fontSize: "24px",
                      textAlign: "right",
                    }}
                  >
                    {report.icon}
                  </Box>
                  <Box
                    sx={{ flex: "3", textAlign: "left", cursor: "pointer" }}
                    onClick={() => navigate(report.route)}
                  >
                    <Box sx={{ fontWeight: "bold" }}>{report.name}</Box>
                    <Box>Details ...</Box>
                  </Box>
                </Box>
              </Item>
            </Grid>
          ))}
          {(role === "Admin" || role === "Company") &&
            reports.map((report, index) => (
              <Grid item xs={2} sm={6} md={6} key={index}>
                <Item>
                  <Box sx={{ flexGrow: 1, display: "flex" }}>
                    <Box
                      sx={{
                        flex: "1",
                        mt: 1,
                        mr: 2,
                        fontSize: "24px",
                        textAlign: "right",
                      }}
                    >
                      {report.icon}
                    </Box>
                    <Box
                      sx={{ flex: "3", textAlign: "left", cursor: "pointer" }}
                      onClick={() => navigate(report.route)}
                    >
                      <Box sx={{ fontWeight: "bold" }}>{report.name}</Box>
                      <Box>Details ...</Box>
                    </Box>
                  </Box>
                </Item>
              </Grid>
            ))}
        </Grid>
      </Box>
    </>
  );
};

export default React.memo(ReportsList);
