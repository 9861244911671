import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import FormSite from "./FormSite";
import ModalBox from "../../ui/ModalBox";
// Yup
import * as Yup from "yup";
import { Typography } from "@mui/material";
import { useSite } from "./Provider";

const AddSite = () => {
  const navigate = useNavigate();
  const [siteName, setSiteName] = useState("x");
  const [open, setOpen] = useState(false);
  const {
    state: { createdSiteId },
  } = useSite();

  // yup schema
  const formSchema = Yup.object().shape({
    serviceType: Yup.string().required("this field is required"),
    site: Yup.object().shape({
      companyId: Yup.string(),
      siteName: Yup.string()
        .required("this field is required")
        .max(2000, "must be less than or equal to 2000 character"),
      countryCode: Yup.string().required("this field is required"),
      country: Yup.string()
        .required("this field is required")
        .max(2000, "must be less than or equal to 2000 character"),
      address: Yup.string().max(
        2000,
        "must be less than or equal to 2000 character"
      ),
      mobile: Yup.string()
        .matches(/^[0-9]*$/, "Must be only digits")
        .min(0)
        .max(25, "must be less than 25 digits"),
      latitude: Yup.number(),
      longitude: Yup.number(),
      capacity: Yup.number()
        .min(0, "must be equal or greater than 0")
        .max(2000, "must be equal or later than 2000"),
      loginRange: Yup.number()
        .integer("must be an integer number")
        .min(0)
        .max(500),
      printTitle: Yup.string().max(
        2000,
        "must be less than or equal to 2000 character"
      ),
      printText: Yup.string().max(
        2000,
        "must be less than or equal to 2000 character"
      ),
      printFooter: Yup.string().max(
        2000,
        "must be less than or equal to 100 character"
      ),
      complaintText: Yup.string().max(
        2000,
        "must be less than or equal to 2000 character"
      ),
      smsMobile: Yup.string()
        .matches(/^[0-9]*$/, "Must be only digits")
        .max(25, "must be less than 26 digits"),
      currency: Yup.string()
        .required("this field is required")
        .max(2000, "must be less than or equal to 2000 character"),
      openTime: Yup.string()
        .matches(
          `^([0-1][0-9]|2[0-3]):[0-5][0-9]$`,
          "Open Time must match the following: HH:MM"
        )
        .max(5, "must be less than 5 characters")
        .required("this field is required"),
      openDuration: Yup.string()
        .matches(
          `^([0-1][0-9]|2[0-3]):[0-5][0-9]$`,
          "Open Time must match the following: HH:MM"
        )
        .max(5, "must be less than 5 characters")
        .required("this field is required"),
      // openDuration: Yup.number()
      //   .min(60)
      //   .max(1440)
      //   .required("this field is required"),
      isActive: Yup.boolean(),
      counter: Yup.object().shape({
        counterNo: Yup.number().integer("must be an integer number").min(0),
        counterMaxvalue: Yup.number()
          .integer("must be an integer number")
          .min(0),
        counterRenwalTime: Yup.string()
          .matches(
            `^([0-1][0-9]|2[0-3]):[0-5][0-9]$|^.{0}$`,
            "Counter Renwal Time must match the following: HH:MM"
          )
          .max(5, "must be less than 5 characters"),
      }),
    }),
    printDivisions: Yup.array(),
    zone: Yup.object().shape({
      title: Yup.string()
        .required("this field is required")
        .max(2000, "must be less than or equal to 2000 character"),
    }),
    service: Yup.object().shape({
      serviceTypeId: Yup.number().required("this field is required"),
      title: Yup.string()
        .required("this field is required")
        .max(2000, "must be less than or equal to 2000 character"),
      freePeriod: Yup.number().min(0).max(1440),
      fixPriceTrial: Yup.number().min(0).max(2147483647),
      fixPriceOfficial: Yup.number().min(0).max(2147483647),
      period1: Yup.number().min(0).max(24),
      hours1: Yup.number().min(0).max(24),
      price1Trial: Yup.number().min(0).max(2147483647),
      price1Official: Yup.number().min(0).max(2147483647),
      period2: Yup.number().min(0).max(24),
      hours2: Yup.number().min(0).max(24),
      price2Trial: Yup.number().min(0).max(2147483647),
      price2Official: Yup.number().min(0).max(2147483647),
      hours3: Yup.number().min(0).max(24),
      price3Trial: Yup.number().min(0).max(2147483647),
      price3Official: Yup.number().min(0).max(2147483647),
      printDivision1: Yup.number().min(0).max(10),
      printDivision2: Yup.number().min(0).max(10),
      printDivision3: Yup.number().min(0).max(10),
      printDivision4: Yup.number().min(0).max(10),
      isActive: Yup.boolean(),
    }),
  });

  const handleClose = () => {
    setOpen(false);
    navigate(-1);
  };
  const handleSuccess = () => {
    setOpen(true);
  };
  return (
    <div>
      <Typography variant="Poster" component="h3" gutterBottom>
        Add Site
      </Typography>
      <div>
        <FormSite
          handleSuccess={handleSuccess}
          setSiteName={setSiteName}
          formSchema={formSchema}
        />
      </div>
      <ModalBox
        open={open}
        handleClose={handleClose}
        operation="add"
        text="The add operation has been completed successfully.. You added a new site "
        navigateLink={[
          `/sites/SiteDetail/${createdSiteId}/AddRole`,
          // `/sites/SiteDetail/${createdSiteId}/TicketPriningFields`,
        ]}
        navigateMessage={[
          "Click here to specify Hourly Role Rate",
          // "Click here to specify Ticket Printing Fields",
        ]}
        name={siteName}
      />
    </div>
  );
};

export default React.memo(AddSite);
