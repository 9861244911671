import {
  Alert,
  Box,
  Button,
  FormControl,
  MenuItem,
  Select,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useCompany } from "../../Companies/Provider";
import { useSite } from "../../Sites/Provider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker, DateTimePicker } from "@mui/x-date-pickers";
import Filter from "./Filter";
import { createYupSchema } from "../../../utilities/yupSchemaCreator";
import { useForm } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import styled from "styled-components";
import { FilterList } from "@mui/icons-material";
import { useReports } from "../Provider";
import { handleErrorResponse } from "../../../utilities/functions";
import { useNavigate, useParams } from "react-router-dom";
import ErrorsList from "../../../ui/ErrorsList";
import { useAuth } from "../../../services/auth";
import { useSubCompany } from "../../SubCompanies/Provider";
import dayjs from "dayjs";
import "dayjs/locale/de";
import "dayjs/locale/en-gb";

// yup schema
const yupSchema = Filter.reduce(createYupSchema, {});
const formSchema = Yup.object().shape(yupSchema);
const locales = ["en-gb"];
function FilterForm({
  obj,
  setReports,
  Reports,
  PageName,
  filterClick,
  setFilterClick,
}) {
  const { name } = useParams();
  const [locale, setLocale] = useState("en-gb");

  const GridGroup1 = styled.div`
    width: fit-content;
    display: grid;
    padding: 20px;
    margin-bottom: 10px;
    grid-template-columns: ${name === "Shift"
      ? "325px 325px"
      : "325px 325px 150px"};
    gap: 10px;
    grid-template-rows: 75px;
    grid-column-gap: 10px;
    background: #f7f9fb;
  `;
  let theme = useTheme();
  let navigate = useNavigate();
  const { role, adminOn } = useAuth();

  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(formSchema),
  });
  const {
    state: { Names: sites, selected: SiteInfo },
    fetchSiteNames: fetchSites,
  } = useSite();
  const {
    state: { Names: companies },
    fetchNames: fetchCompanies,
  } = useCompany();
  const {
    state: {
      listShift: SiteShiftList,
      ExportExcelList,
      ExportShiftExcelList,
      ExportVoidExcelList,
      page,
      perPage,
      pageVoid,
      perPageVoid,
      companyIdAtt,
      siteIdAtt,
      FilterData,
      DailySales,
      totalSize,
    },
    fetchListShift: getSiteShiftList,
    fetchTransaction: getSiteTransactionList,
    fetchTransactionExcel,
    fetchShiftTransactionExcel,
    fetchVoidTransactionExcel,
    fetchLessPayroll,
    fetchHighestPayroll,
    fetchSitesBySales,
    fetchSitesByTickets,
    fetchVoidTransaction,
    fetchTransactionService,
    fetchEmployeesRoles,
    DailySalesPayroll,
    fetchPrevPayroll,
    dispatch,
  } = useReports();
  const {
    state: { selected: subCompany },
  } = useSubCompany();

  const [siteId, setSiteId] = useState();
  const [companyId, setCompanyId] = useState();
  const [Error, setError] = useState("");
  const [FromDate, setFromDate] = useState();
  const [ToDate, setToDate] = useState();
  //fetch companies
  useEffect(() => {
    if (role === "Admin") {
      if (companies?.length === 0) fetchCompanies();
    }
  }, [fetchCompanies]);
  useEffect(() => {
    if (name === "Shift") {
      if (role === "Site")
        getSiteShiftList({ sid: adminOn?.id }, page, perPage);
      else if (role === "Admin" || role === "Company" || role === "SubCompany")
        if (getValues("cid") > 0) getSiteShiftList(getValues(), page, perPage);
    }
  }, [getSiteShiftList]);
  useEffect(() => {
    if (role === "Admin") {
      if (companyIdAtt) {
        fetchSites(companyIdAtt, 0);
      }
    } else if (role === "Company") {
      fetchSites(adminOn?.id, 0);
    } else if (role === "SubCompany") {
      fetchSites(subCompany?.companyId, adminOn?.id);
    }
  }, [fetchSites, companyIdAtt]);
  const getData = (name) => {
    switch (name) {
      case "cid":
        return companies;
      case "sid":
        return sites;
    }
  };
  const onSubmit = (values) => {
    dispatch({ type: "set_selectedSite", payload: null });
    if ((name === "Shift" || name === "Transactions") && !values.sid) {
      setError({ title: "You must select site before click filter button!" });
    } else if (
      (name === "SiteBySales" ||
        name === "SitesByTickets" ||
        name === "LessPayroll" ||
        name === "HighestPayroll") &&
      !values.fromDate
    ) {
      setError({ title: "Select from date" });
    } else if (
      (name === "SiteBySales" ||
        name === "SitesByTickets" ||
        name === "LessPayroll" ||
        name === "HighestPayroll") &&
      !values.toDate
    ) {
      setError({ title: "Select to date" });
    } else {
      dispatch({ type: "set_FilterData", payload: values });
      try {
        switch (name) {
          case "Shift":
            {
              getSiteShiftList(values, page, perPage);
              if (ExportShiftExcelList.length != 0) {
                ExportShiftExcelList.splice(0, ExportShiftExcelList.length);
              }
              fetchShiftTransactionExcel(values, 1, 100);
              setError(null);
            }
            break;
          case "Transactions":
            {
              getSiteTransactionList(values, page, perPage);
              if (ExportExcelList.length != 0) {
                ExportExcelList.splice(0, ExportExcelList.length);
              }
              fetchTransactionExcel(values, 1, 100);
              setError(null);
            }
            break;
          case "EmployeesRoles":
            {
              fetchEmployeesRoles(values);
              setError(null);
            }
            break;
          case "DailySales":
            {
              if (!values.fromDate || !values.toDate) {
                setError({ title: "Select from date and to date at first " });
              } else {
                DailySalesPayroll(companyIdAtt, values);
                setError(null);
              }
            }
            break;
          case "TransactionsByService":
            {
              fetchTransactionService(values, page, perPage);
              setError(null);
            }
            break;
          case "Voids":
            {
              fetchVoidTransaction(values, pageVoid, perPageVoid);
              if (ExportVoidExcelList.length != 0) {
                ExportVoidExcelList.splice(0, ExportVoidExcelList.length);
              }
              fetchVoidTransactionExcel(values, 1, 100);
              setError(null);
            }
            break;
          case "LessPayroll":
            {
              fetchLessPayroll(companyIdAtt, values);
              setError(null);
            }
            break;
          case "HighestPayroll":
            {
              fetchHighestPayroll(companyIdAtt, values);
              setError(null);
            }
            break;
          case "PreviewPayroll":
            {
              fetchPrevPayroll(companyIdAtt, values);
              setError(null);
            }
            break;
          case "SiteBySales":
            {
              fetchSitesBySales(companyIdAtt, values);
              setError(null);
            }
            break;
          case "SitesByTickets":
            {
              fetchSitesByTickets(companyIdAtt, values);
              setError(null);
            }
            break;
        }
      } catch (error) {
        handleErrorResponse(error.status);
      }
    }
  };
  useEffect(() => {
    if (getValues("cid") > 0 && name === "Shift") {
      getSiteShiftList(getValues(), page, perPage);
    } else if (getValues("cid") > 0 && name === "Transactions")
      getSiteTransactionList(getValues(), page, perPage);
    else if (getValues("cid") > 0 && name === "Voids")
      fetchVoidTransaction(getValues(), page, perPage);
  }, [page, perPage]);

  useEffect(() => {
    if (role === "Company") {
      setValue("cid", adminOn.id);
      dispatch({ type: "set_CompanyIdAtt", payload: adminOn.id });
    } else if (role === "Site") {
      setValue("cid", SiteInfo?.company?.id);
      setValue("sid", adminOn?.id);
    } else if (role === "SubCompany") {
      setValue("cid", subCompany?.companyId);
    }
  }, [adminOn]);
  useEffect(() => {
    dispatch({ type: "set_CompanyIdAtt", payload: null });
    dispatch({
      type: "set_SiteIdAtt",
      payload: 0,
    });

    dispatch({ type: "set_totalSize", payload: null });
    dispatch({ type: "set_DailySales", payload: [] });
    dispatch({ type: "set_listShift", payload: [] });
    dispatch({ type: "set_listVoidTransactions", payload: [] });
    dispatch({ type: "set_EmployeesRoles", payload: [] });
    dispatch({ type: "set_DailySales", payload: [] });
    dispatch({ type: "set_listTransactionService", payload: [] });
    dispatch({ type: "set_listlessPayroll", payload: [] });
    dispatch({ type: "set_listSitesSales", payload: [] });
    dispatch({ type: "set_listHighPayroll", payload: [] });
    dispatch({ type: "set_listSitesTickets", payload: [] });
    dispatch({ type: "set_listTransaction", payload: [] });
  }, []);

  return (
    <form onSubmit={handleSubmit(onSubmit)} noValidate>
      <Box>
        {Error && (
          <Alert severity="error">
            <ErrorsList error={Error} />
          </Alert>
        )}
        {sites.length === 0 && companyId ? (
          <Alert severity="error">
            <Typography variant="Poster" component="span">
              {" "}
              You should add a new Site first,{" "}
              <Typography
                variant="Poster"
                component="span"
                onClick={() => navigate(`/sites/add/${companyId}`)}
                sx={{
                  "&:hover": {
                    textDecoration: "underline",
                    cursor: "pointer",
                  },
                }}
              >
                <strong>Add Site</strong>
              </Typography>
            </Typography>
          </Alert>
        ) : (
          ""
        )}
      </Box>
      <Box sx={{ background: "#f7f9fb", padding: "20px", mt: 1, width: "85%" }}>
        <GridGroup1>
          {(role === "Company" || role === "Site") && (
            <>
              <Box
                sx={{
                  background: "#f7f9fb",
                  width: "70%",
                }}
              >
                <span style={{ fontSize: "12px" }}>Company Name</span>
                <Box
                  sx={{
                    borderBottom: "1px solid gray",
                    width: "290px",
                    borderRadius: "8px",
                    textAlign: "center",
                    marginRight: "20px",
                    marginTop: "15px",
                  }}
                >
                  {role === "Company"
                    ? adminOn?.companyName
                    : SiteInfo?.company?.companyName}
                </Box>
              </Box>
            </>
          )}
          {role === "SubCompany" && (
            <>
              <Box
                sx={{
                  background: "#f7f9fb",
                  width: "70%",
                }}
              >
                <span style={{ fontSize: "12px" }}>Company Name</span>
                <Box
                  sx={{
                    borderBottom: "1px solid gray",
                    width: "290px",
                    borderRadius: "8px",
                    textAlign: "center",
                    marginRight: "20px",
                    marginTop: "15px",
                  }}
                >
                  {subCompany?.company?.companyName}
                </Box>
              </Box>
            </>
          )}
          {role === "Site" && (
            <>
              <Box
                sx={{
                  background: "#f7f9fb",
                  width: "100%",
                }}
              >
                <span style={{ fontSize: "12px" }}>Site Name</span>
                <div style={{ display: "flex" }}>
                  <Box
                    sx={{
                      borderBottom: "1px solid gray",
                      width: "290px",
                      borderRadius: "8px",
                      textAlign: "center",
                      marginRight: "20px",
                      marginTop: "15px",
                    }}
                  >
                    {adminOn?.siteName}
                  </Box>
                </div>
              </Box>
            </>
          )}
          {Filter.map((f, index) => (
            <React.Fragment key={index}>
              {f.select && (
                <FormControl
                  sx={{
                    width: theme.dimensions.width,
                    mt: theme.dimensions.mt,
                    mb: theme.dimensions.mb,
                    display:
                      (role !== "Admin" && f.name === "cid") ||
                      (role === "Site" && f.name === "sid") ||
                      (name === "LessPayroll" && f.name === "sid") ||
                      (name === "HighestPayroll" && f.name === "sid") ||
                      (name === "SiteBySales" && f.name === "sid") ||
                      (name === "SitesByTickets" && f.name === "sid") ||
                      (name === "DailySales" && f.name === "sid")
                        ? "none"
                        : null,
                  }}
                  error={f.name === "cid" || f.name === "sid"}
                >
                  <span style={{ fontSize: "12px" }}>{f.label}*</span>
                  <Select
                    sx={{
                      mt: theme.dimensions.mt,
                    }}
                    error={!!errors[f.name]}
                    value={
                      (f.name === "cid" && companyIdAtt) ||
                      (f.name === "sid" && siteIdAtt)
                    }
                    onChange={
                      f.name === "cid"
                        ? (e) => {
                            dispatch({
                              type: "set_CompanyIdAtt",
                              payload: e.target.value,
                            });
                          }
                        : (e) => {
                            dispatch({
                              type: "set_SiteIdAtt",
                              payload: e.target.value,
                            });
                          }
                    }
                    inputProps={{
                      ...register(f?.name),
                    }}
                    size="small"
                  >
                    {getData(f.name)?.map((s, index) => (
                      <MenuItem key={index} value={s.id}>
                        {s[f.data]}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}
              {f.number && name === "Transactions" && (
                <Box>
                  <span style={{ fontSize: "12px" }}>{f.label}</span>
                  <TextField
                    InputProps={{
                      inputProps: { min: 0 },
                      ...register(f?.name),
                    }}
                    defaultValue={0}
                    error={!!errors[f.name]}
                    helperText={errors[f.name]?.message}
                    required
                    type="number"
                    size="small"
                    sx={{ width: theme.dimensions.width / 2 }}
                  />
                </Box>
              )}

              {f.date && (
                <LocalizationProvider
                  dateAdapter={AdapterDayjs}
                  adapterLocale={locale}
                >
                  <FormControl
                    components={["DateRangePicker"]}
                    sx={{
                      width: theme.dimensions.width,
                      mt: theme.dimensions.mt,
                      mb: theme.dimensions.mb,
                    }}
                  >
                    <span style={{ fontSize: "12px" }}>
                      {f.label}
                      {(name === "LessPayroll" ||
                        name === "HighestPayroll" ||
                        name === "SiteBySales" ||
                        name === "SitesByTickets" ||
                        name === "DailySales") && <>*</>}
                    </span>

                    <DateTimePicker
                      sx={{ mt: 1 }}
                      value={f.name === "fromDate" ? FromDate : ToDate}
                      onChange={
                        f.name === "fromDate"
                          ? (date) => {
                              setValue(f.name, date);
                              setFromDate(date);
                            }
                          : (date) => {
                              setValue(f.name, date);
                              setToDate(date);
                            }
                      }
                      error={!!errors[f.name]}
                      helperText={errors[f.name]?.message}
                      slotProps={{
                        textField: {
                          error: errors[f.name]?.message,
                          helperText: (
                            <p style={{ color: "#d32f2f" }}>
                              {errors[f.name]?.message}
                            </p>
                          ),
                          size: "small",
                        },
                      }}
                    />
                  </FormControl>
                </LocalizationProvider>
              )}
            </React.Fragment>
          ))}
        </GridGroup1>
        <Box
          sx={{
            display: "flex",
            justifyContent: "end",
            alignContent: "start",
            mr: "30px",
            mt: "-30px",
          }}
        >
          <Button
            variant="contained"
            type="submit"
            sx={{
              backgroundColor: theme.color.blackBackground,
              color: "white",
              ml: 5,
              mt: 2,
              "&:hover": { background: `${theme.color.blackBackground}` },
              textTransform: "capitalize",
            }}
            onClick={() => setFilterClick(true)}
          >
            <FilterList />
            <span style={{ margin: theme.spacing(0, 2) }}>Filter</span>
          </Button>
        </Box>
      </Box>
    </form>
  );
}

export default FilterForm;
