import { notifyFaild } from "../ui/Toast";

import dayjs from "dayjs";
import "dayjs/locale/de";
const firstUp = (s) => {
  return s.charAt(0).toUpperCase() + s.slice(1);
};

const copyToClipBoard = async (copyMe, setCopySuccess, CopySuccess) => {
  try {
    await navigator.clipboard.writeText(copyMe);
    setCopySuccess(copyMe);
  } catch (err) {
    setCopySuccess("Failed to copy!");
  }
};
const handleError = (errorCode, navigate) => {
  navigate("error");
};

function handleResponse(response) {
  switch (response) {
    case 500:
      return notifyFaild("error 500 internal exception");
    default:
      break;
  }
}

function CalculateCountArray({ row }) {
  return row.employees.length;
}
function CalDate(date) {
  if (date) {
    var dat = new Date(date);
    let day = dat.getDate();
    let month = dat.getMonth() + 1;
    let year = dat.getFullYear();
    if (day < 10) day = "0" + day;
    if (month < 10) month = "0" + month;
    var newDate = year + "-" + month + "-" + day;
    return newDate;
  }
}
function CalTime(date) {
  if (date) {
    var time = new Date(toUtc(date));
    let day = time.getDate();
    let month = time.getMonth() + 1;
    let year = time.getFullYear();
    var min = time.getMinutes();
    var hours = time.getHours();
    if (min < 10) min = "0" + min;
    if (hours < 10) hours = "0" + hours;
    if (hours === 0) hours = "00";
    if (day < 10) day = "0" + day;
    if (month < 10) month = "0" + month;
    var newTime = year + "-" + month + "-" + day + " " + hours + ":" + min;
    return newTime;
  }
}
function toLocal(d) {
  if (d) {
    var date = new Date(d);
    let day = date.getDate();
    let month = date.getMonth() + 1;
    let year = date.getFullYear();
    var min = date.getMinutes();
    var hours = date.getHours();
    if (min < 10) min = "0" + min;
    if (hours < 10) hours = "0" + hours;
    if (hours === 0) hours = "00";
    if (day < 10) day = "0" + day;
    if (month < 10) month = "0" + month;
    var newTime = year + "-" + month + "-" + day + " " + hours + ":" + min;
    return newTime;
  }
}
function Diff(punchIn, punchOut) {
  var Pin = new Date(punchIn);
  var pot = new Date(punchOut);
  var hpi = Pin.getTime();
  var hpt = pot.getTime();
  var diff = (hpt - hpi) / 1000;
  diff /= 60 * 60;
  return Math.abs(Math.round(diff));
}
function StyleTime(date) {
  if (date) {
    var time = new Date(date);

    var min = time.getMinutes();
    var hours = time.getHours();
    if (min < 10) min = "0" + min;
    if (hours < 10) hours = "0" + hours;

    var newTime = hours + ":" + min;
    return newTime;
  }
}
function StyleDate(date) {
  var today = new Date(date);
  var dd = today.getDate();
  var mm = today.getMonth() + 1; //January is 0!
  var yyyy = today.getFullYear();
  if (dd < 10) {
    dd = "0" + dd;
  }
  if (mm < 10) {
    mm = "0" + mm;
  }
  today = yyyy + "-" + mm + "-" + dd;
  return today;
}
function showDate(date) {
  if (date) {
    var dat = new Date(date);
    let day = dat.getDate();
    let month = dat.getMonth();
    let year = dat.getFullYear();
    var newDate = month + "/" + day + "/" + year;
    return newDate;
  }
}
function FixedTypeService(services) {
  var fixed = services?.filter(
    (service) => service.serviceTypeId !== 2 && service.serviceTypeId !== 4
  );
  return fixed;
}
function HourlyTypeService(services) {
  var houlrytypes = services?.filter(
    (service) => service.serviceTypeId === 2 || service.serviceTypeId === 4
  );
  return houlrytypes;
}
function TransformtoPercent(number) {
  return Math.round(number * 100);
}
function TransformFromPercent(number, handleCheck) {
  return number / 100;
}
function HourlyValidation(e, setError) {
  if (
    e[0].period1 == 0 &&
    e[0].hours1 == 0 &&
    (e[0].hours2 != 0 || e[0].period2 != 0 || e[0].hours3 != 0)
  ) {
    setError({
      title:
        " period 1 and hours 1 must not be 0 when you have period 2 and hours 2",
    });
    return false;
  } else if (e[0].hours2 == 0 && e[0].period2 == 0 && e[0].hours3 != 0) {
    setError({
      title: "period 2 and hours 2 must not be 0 when you have hours 3",
    });
    return false;
  } else if (
    e[0].period1 != 0 &&
    e[0].hours1 != 0 &&
    e[0].period1 % e[0].hours1 != 0
  ) {
    setError({ title: "The period 1 must be a multiple of hours 1" });
    return false;
  } else if (
    e[0].period2 != 0 &&
    e[0].hours2 != 0 &&
    e[0].period2 % e[0].hours2 != 0
  ) {
    setError({ title: "The period 2 must be a multiple of hours 2" });
    return false;
  } else return true;
}
function FixedValidation(e, setError) {
  if (e[0].fixPriceTrial < 0 || e[0].fixPriceOfficial < 0) {
    setError({ title: "Enter number greater than 0" });
    return false;
  } else return true;
}
function handleErrorResponse(status, title) {
  var message;
  switch (status) {
    case 400:
      message = "You must enter correct data!!";
      break;
    case 404:
      message = "Not found!!";
      break;
    case 405:
      message = `Faild delete you used this ${title} some where`;
      break;
    case 409:
      message = `${title} name already exist`;
      break;
    case 403:
      message = "User not permitted access";
    case 500:
      message = "Server error (500)";
      break;
  }
  return message;
}
function isValidDate(pin, pout) {
  var din = new Date(pin);
  var dout = new Date(pout);
  if (dout > din) return true;
  else return false;
}
function tolocalDate(date) {
  if (date.length === 10) {
    date = date + " " + "00:00";
  }
  var d = new Date(date);
  let day = d.getDate();
  let month = d.getMonth() + 1;
  let year = d.getFullYear();
  let hour = d.getHours();
  let min = d.getMinutes();
  let sec = d.getSeconds();
  if (day < 10) day = "0" + day;
  if (month < 10) month = "0" + month;
  if (hour < 10) hour = "0" + hour;
  if (min < 10) min = "0" + min;
  var utc =
    year +
    "-" +
    month +
    "-" +
    day +
    "T" +
    hour +
    ":" +
    min +
    ":" +
    "00.000" +
    "Z";
  var local = new Date(utc).toLocaleString("en-GB", { hour12: true });

  return local;
}
const newValueConvert = (date) => {
  if (date) {
    var d = new Date(date);
    let day = d.getDate();
    let month = d.getMonth() + 1;
    let year = d.getFullYear();
    let hour = d.getHours();
    let min = d.getMinutes();
    if (day < 10) day = "0" + day;
    if (month < 10) month = "0" + month;
    if (hour < 10) hour = "0" + hour;
    if (min < 10) min = "0" + min;
    var utc =
      year +
      "-" +
      month +
      "-" +
      day +
      "T" +
      hour +
      ":" +
      min +
      ":" +
      "00.000" +
      "Z";
    var local = new Date(utc).toLocaleString("US");
    return dayjs(local);
  } else return "";
};
function showLocalDate(date) {
  if (date.length === 10) {
    date = date + " " + "00:00";
  }
  var d = new Date(date);

  let day = d.getDate();
  let month = d.getMonth() + 1;
  let year = d.getFullYear();
  let hour = d.getHours();
  let min = d.getMinutes();
  let sec = d.getSeconds();
  if (day < 10) day = "0" + day;
  if (month < 10) month = "0" + month;
  if (hour < 10) hour = "0" + hour;
  if (min < 10) min = "0" + min;

  var utc =
    year +
    "-" +
    month +
    "-" +
    day +
    "T" +
    hour +
    ":" +
    min +
    ":" +
    "00.000" +
    "Z";
  var local = new Date(utc).toLocaleString("en-GB", { hour12: true }).split("");
  return local;
}
function toUtc(date) {
  let utc = new Date(date).toUTCString();
  return utc;
}

function CalUtcWithFormat(date) {
  const now = new Date(date);
  const now_utc = new Date(now.toUTCString().slice(0, -4));

  let year = now_utc.getFullYear();
  let month = now_utc.getMonth() + 1;
  let day = now_utc.getDate();
  let hours = now_utc.getHours();
  let min = now_utc.getMinutes();

  if (day < 10) day = "0" + day;
  if (month < 10) month = "0" + month;
  if (hours < 10) hours = "0" + hours;
  if (min < 10) min = "0" + min;
  return year + "-" + month + "-" + day + " " + hours + ":" + min;
}

function insertField(el, newText) {
  var start = el.selectionStart;
  var end = el.selectionEnd;
  var text = document.getElementById("text").value;
  var before = text.substring(0, start);
  var after = text.substring(end, text.length);
  el.value = before + newText + after;
  el.selectionStart = start + newText.length;
  el.selectionEnd = start + newText.length;
}

function rmValueFromObj(obj, valuesList) {
  return Object.fromEntries(
    Object.entries(obj).filter(([v, _]) => !valuesList.includes(v))
  );
}
function StyledXlsx(data) {
  var dataArray = new Array();
  dataArray[0] = {
    Ticket: `Transfer Ticket: ${data?.transferredTickets}`,
    " ": "",
    Car: `Transfer Car: ${data?.carsTransferred}`,
  };
  dataArray[1] = {
    Ticket: `Printed Ticket: ${data?.printedTickets}`,
    " ": "",
    Car: `Car Check In:${data?.carsCheckedIn}`,
  };
  dataArray[2] = {
    Ticket: `Transfer Ticket In: ${data?.transferredTicketsIn}`,
    " ": "",
    Car: `Car Check Out${data?.carsCheckedOut}`,
  };
  dataArray[3] = {
    Ticket: `Transfer Ticket Out: ${data?.transferredTicketsOut}`,
    " ": "",
    Car: `Car Transfer In: ${data?.carsTransferredIn}`,
  };
  dataArray[4] = {
    Ticket: `Printed Ticket In: ${data?.printedTicketsIn}`,
    " ": "",
    Car: `Car Transfer Out: ${data?.carsTransferredOut}`,
  };
  dataArray[5] = {
    Ticket: `Printed Ticket Out: ${data?.printedTicketsOut}`,
    " ": "",
    Car: `Remaining Car: ${data?.remainingCar}`,
  };
  dataArray[6] = {
    Ticket: `Remaining Ticket: ${data?.remainingTicket}`,
    " ": "",
    Car: `Car First Check In: ${data?.firstCheckIn}`,
  };
  dataArray[7] = {
    Ticket: "",
    " ": "",
    Car: `Car Last Check In: ${data?.lastCheckIn}`,
  };
  dataArray[8] = {
    Ticket: " ",
    " ": "",
    Car: ` `,
  };
  dataArray[9] = {
    Ticket: "Payment",
    " ": "",
    Car: `Pre Payment`,
  };
  dataArray[10] = {
    Ticket: `Void: ${data.void}`,
    " ": "",
    Car: `Pre Void: ${data?.preVoid}`,
  };
  dataArray[11] = {
    Ticket: `Company: ${data.comp}`,
    " ": "",
    Car: `Pre Company: ${data?.preComp}`,
  };
  dataArray[12] = {
    Ticket: `Company Count: ${data.compCount}`,
    " ": "",
    Car: `Pre Company Count: ${data?.preCompCount}`,
  };
  dataArray[13] = {
    Ticket: `Cash: ${data.cash}`,
    " ": "",
    Car: `Pre Cash: ${data?.preCash}`,
  };
  dataArray[14] = {
    Ticket: `Cash Count: ${data?.cashCount}`,
    " ": "",
    Car: `Pre Cash Count: ${data?.preCashCount}`,
  };
  dataArray[15] = {
    Ticket: `Cash Member: ${data?.memberCash}`,
    " ": "",
    Car: `Pre CC: ${data?.preCC}`,
  };
  dataArray[16] = {
    Ticket: `CC: ${data?.cc}`,
    " ": "",
    Car: `Pre CC Count: ${data?.preCCCount}`,
  };
  dataArray[17] = {
    Ticket: `Member CC: ${data?.memberCC}`,
    " ": "",
    Car: ``,
  };
  dataArray[18] = {
    Ticket: `Member CMS Cash: ${
      data?.memberCMSCash ? data?.memberCMSCash : ""
    }`,
    " ": "",
    Car: ``,
  };
  dataArray[19] = {
    Ticket: `Member CMS CC: ${data?.memberCMSCC ? data?.memberCMSCC : ""}`,
    " ": "",
    Car: ``,
  };
  dataArray[20] = {
    Ticket: `Cash Tips: ${data?.cashTips}`,
    " ": "",
    Car: ``,
  };
  dataArray[21] = {
    Ticket: `Non Cash Tips: ${data?.nonCashTips}`,
    " ": "",
    Car: ``,
  };
  dataArray[22] = {
    Ticket: `Member Cash Tips: ${data?.memberCashTips}`,
    " ": "",
    Car: ``,
  };
  dataArray[23] = {
    Ticket: `Member Non Cash Tips: ${data?.memberNonCashTips}`,
    " ": "",
    Car: ``,
  };
  dataArray[24] = {
    Ticket: `Member CMS Cash Tips: ${
      data?.memberCMSCashTips ? data?.memberCMSCashTips : ""
    }`,
    " ": "",
    Car: ``,
  };
  dataArray[25] = {
    Ticket: `Member CMS Non Cash Tips: ${
      data?.memberCMSNonCashTips ? data?.memberCMSNonCashTips : ""
    }`,
    " ": "",
    Car: ``,
  };
  return dataArray;
}

const startOfWeek = (date) => {
  if (date) {
    var diff = date?.getDate() - date.getDay() + (date.getDay() === 0 ? -6 : 1);

    return new Date(date?.setDate(diff));
  }
};

const startOfMonth = (date) => {
  var dt = new Date(date);
  var month = dt.getMonth() + 1;
  var year = dt.getFullYear();
  return new Date(`${year}-${month}-01`);
};
function padZero(time) {
  return (time < 10 ? "0" : "") + time;
}
const convertToHours = (minutes) => {
  let hours = Math.floor(minutes / 60);
  let mins = minutes % 60;
  return `${padZero(hours)}:${padZero(mins)}`;
};
const uniqueArray = (Array) => {
  return Array?.filter(
    (obj, index, self) =>
      index === self.findIndex((t) => t.siteId === obj.siteId)
  );
};
export {
  firstUp,
  copyToClipBoard,
  handleError,
  handleResponse,
  CalDate,
  showDate,
  CalculateCountArray,
  FixedTypeService,
  HourlyTypeService,
  TransformtoPercent,
  TransformFromPercent,
  HourlyValidation,
  handleErrorResponse,
  FixedValidation,
  CalTime,
  tolocalDate,
  toUtc,
  StyleTime,
  StyleDate,
  Diff,
  isValidDate,
  insertField,
  StyledXlsx,
  toLocal,
  showLocalDate,
  uniqueArray,
  CalUtcWithFormat,
  startOfWeek,
  startOfMonth,
  convertToHours,
  newValueConvert,
};
