// providers & react
import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useSite } from "./Provider";
// components
import FormSite from "./FormSite";
import ModalBox from "../../ui/ModalBox";
// Yup
import * as Yup from "yup";

const UpdateSite = () => {
  // providers and hooks
  const [open, setOpen] = useState(false);
  const { id } = useParams();
  const navigate = useNavigate();
  const {
    state: { selected: site },
    fetchOne: fetchSite,
  } = useSite();
  const [siteName, setSiteName] = useState(site ? site.siteName : "x");

  // yup schema
  const formSchema = Yup.object().shape({
    site: Yup.object().shape({
      companyId: Yup.string(),
      siteName: Yup.string()
        .required("this field is required")
        .max(2000, "must be less than or equal to 2000 character"),
      countryCode: Yup.string().required("this field is required"),
      country: Yup.string()
        .required("this field is required")
        .max(2000, "must be less than or equal to 2000 character"),
      address: Yup.string().max(
        2000,
        "must be less than or equal to 2000 character"
      ),
      mobile: Yup.string()
        .matches(/^[0-9]*$/, "Must be only digits")
        .min(0)
        .max(25, "must be less than 10 digits"),
      latitude: Yup.number(),
      longitude: Yup.number(),
      capacity: Yup.number()
        .min(0, "must be equal or greater than 0")
        .max(2000, "must be equal or later than 2000"),
      loginRange: Yup.number()
        .integer("must be an integer number")
        .min(0)
        .max(500),
      printTitle: Yup.string().max(
        2000,
        "must be less than or equal to 2000 character"
      ),
      printText: Yup.string().max(
        2000,
        "must be less than or equal to 2000 character"
      ),
      printFooter: Yup.string().max(
        2000,
        "must be less than or equal to 2000 character"
      ),
      complaintText: Yup.string().max(
        2000,
        "must be less than or equal to 2000 character"
      ),
      smsMobile: Yup.string()
        .matches(/^[0-9]*$/, "Must be only digits")
        .max(25, "must be less than 26 digits"),
      currency: Yup.string()
        .required("this field is required")
        .max(2000, "must be less than or equal to 2000 character"),
      openTime: Yup.string()
        .matches(
          `^([0-1][0-9]|2[0-3]):[0-5][0-9]$`,
          "Open Time must match the following: HH:MM"
        )
        .required("this field is required"),
      openDuration: Yup.string()
        .matches(
          `^([0-1][0-9]|2[0-3]):[0-5][0-9]$`,
          "Open Time must match the following: HH:MM"
        )
        .required("this field is required"),
      // openDuration: Yup.number()
      //   .min(60)
      //   .max(1440)
      //   .required("this field is required"),
      isActive: Yup.boolean(),
      counter: Yup.object().shape({
        counterMaxvalue: Yup.number().min(0),
        counterRenwalTime: Yup.string().matches(
          `^([0-1][0-9]|2[0-3]):[0-5][0-9]$|^.{0}$`,
          "Counter Renwal Time must match the following: HH:MM"
        ),
      }),
    }),
  });

  // use effects
  useEffect(() => {
    fetchSite(id);
  }, [id, fetchSite]);

  // functions
  const handleSuccess = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    navigate(-1);
  };
  return (
    <div>
      <div
        style={{
          fontSize: "14px",
          fontWeight: "600",
        }}
      >
        Edit Site
      </div>
      <div>
        <FormSite
          site={site}
          handleSuccess={handleSuccess}
          setSiteName={setSiteName}
          formSchema={formSchema}
        />
      </div>
      <ModalBox
        open={open}
        handleClose={handleClose}
        operation="edit"
        text="The update operation has been completed successfully.. You modified the site profile "
        name={siteName}
      />
    </div>
  );
};

export default React.memo(UpdateSite);
