//react component
import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";

//mui material component
import { Alert, Button, Card, Paper, Typography } from "@mui/material";

//dashboard component

import CustomTable from "../../ui/CustomTable";
import { useContract } from "../Contracts/Provider";
import TabsList from "../../ui/TabsList";
import ActiveCell from "../Companies/CompanyTable/ActiveCell";
import { useGate } from "../Gates/Provider";
import { useDisCount } from "../DisCounts/Provider";
import { useZone } from "../Zones/Provider";
import StyledType from "../Gates/StyledType";
import DownloadLink from "./DownloadLink";
import ModalBox from "../../ui/ModalBox";
import InfoSite from "./InfoSite";
import { useMemberPlan } from "../MemberPlans/Provider";
import ErrorsList from "../../ui/ErrorsList";
import { handleErrorResponse } from "../../utilities/functions";
import { useSite } from "./Provider";
import { useAuth } from "../../services/auth";
import { useTemplateMess } from "../TemplateMessage/Provider";
import RenderMess from "../TemplateMessage/Render/RenderMess";
import { useVoidReason } from "../VoidReason/Provider";
import SmsFailedOutlinedIcon from "@mui/icons-material/SmsFailedOutlined";
import { useTheme } from "@emotion/react";
//icons
import styled from "styled-components";
import SMSInfo from "../SMS/SMSInfo";
import WebContentInfo from "../WebContent/WebContentInfo";
import StartContract from "./Render/StartContract";
import EndContract from "./Render/EndContract";
import FirstReminderCell from "./Render/FirstReminderCell";
import LastReminder from "./Render/LastReminder";

const DetailSite = () => {
  let navigate = useNavigate();
  let theme = useTheme();

  const { id } = useParams();
  const { role } = useAuth();

  const [open, setOpen] = useState(false);
  const [reload, setreload] = useState(false);
  const [vState, setVState] = useState(false);
  const [error, setError] = useState(null);
  const [valid, setValid] = useState(true);
  const [Add, setAdd] = useState(false);

  const {
    state: {
      selected: SiteInfo,
      listContract,
      listGate,
      listDiscount,
      listZones,
      listMemberPlan,
      listRoleList,
      listTemplate,
      listReason,
      selectedTab,
      SMS,
      webContent,
    },
    fetchOne: fetchSiteInfo,
    removeContract: rc,
    removeDisCount,
    removeGate,
    removeMemberPlan,
    removeZone,
    removeRole,
    removeTemplate,
    removeReason,
    fetchRoleList,
    updateRole,
    dispatch,
    fetchOneSMS,
  } = useSite();

  const {
    state: { list: templateMessages },
    fetchList: fetchMessages,
  } = useTemplateMess();

  const {
    state: { list: reasons },
    fetchList: fetchReason,
    edit: editReason,
  } = useVoidReason();
  // useEffect(() => {
  //   if (role === "Site")
  //     dispatch({
  //       type: "set_selected_tab",
  //       payload: "Gates",
  //     });
  //   setSelectedCols(selectedTab);
  // }, [dispatch, selectedTab, role]);
  const [isReload, setIsReload] = useState(false);
  // useEffect(() => {
  //   fetchRoleList(id);
  // }, [fetchRoleList, id]);

  const tabs = [
    role !== "Site" && { title: "Contracts", num: listContract?.length },
    { title: "Gates", num: listGate?.length },
    { title: "Discounts", num: listDiscount?.length },
    { title: "Zones", num: listZones?.length },
    { title: "Member plans", num: listMemberPlan?.length },
    { title: "Role", num: listRoleList?.length },
    { title: "Template messages", num: listTemplate?.length },
    { title: "Void Reason", num: listReason?.length },
    { title: "SMS Provider" },
    { title: "Web Content" },
  ];
  //tab which selected
  const [selectedCols, setSelectedCols] = useState(selectedTab);
  useEffect(() => {
    setSelectedCols(selectedTab);
  }, [setSelectedCols]);

  const getCols = (name) => {
    switch (name) {
      case "Contracts":
        return [
          {
            label: "File Name",
            name: "",
            headTextLeft: true,
            textLeft: true,
            customRender: DownloadLink,
          },
          {
            label: "Start Date",
            name: "startDate",
            editable: true,
            helperText: "not empty",
            checkable: true,
            type: "date",
            customRender: StartContract,
          },
          {
            label: "End Date",
            name: "endDate",
            editable: true,
            checkable: true,
            helperText: "not empty",
            type: "date",
            customRender: EndContract,
          },
          {
            label: "First Reminder",
            name: "firstReminder",
            editable: true,
            checkable: true,

            helperText: "not empty",
            customRender: FirstReminderCell,
            type: "date",
          },
          {
            label: "Second Reminder",
            name: "secondReminder",
            editable: true,
            checkable: true,

            helperText: "not empty",
            customRender: LastReminder,
            type: "date",
          },
          {
            label: "Contract Status",
            name: "isActive",
            customRender: ActiveCell,
            editable: true,
          },
        ];

      case "Gates":
        return [
          {
            label: "Gate Name",
            name: "title",
            headTextLeft: true,
            textLeft: true,
            editable: true,
            checkable: true,
            helperText: "not empty",
          },
          {
            label: "Type",
            name: "typeId",
            customRender: StyledType,
            editable: true,
          },
          {
            label: "Gate Status",
            name: "isActive",
            customRender: ActiveCell,
            editable: true,
          },
        ];

      case "Discounts":
        return [
          {
            label: "Discount Discription",
            name: "description",
            headTextLeft: true,
            textLeft: true,
            editable: true,
            checkable: true,
            helperText: "not empty",
          },
          {
            label: "Discount Value",
            name: "discount",
            customRender: StyledType,
            editable: true,
            checkable: true,
            number: true,
            helperText: "not empty or zero value",
          },
          {
            label: "Discount Status",
            name: "isActive",
            editable: true,
            customRender: ActiveCell,
            helperText: "discount must be greater than 0",
          },
        ];

      case "Zones":
        return [
          {
            label: "Site Zone Name",
            name: "title",
            headTextLeft: true,
            textLeft: true,
            editable: true,
            checkable: true,
            helperText: "not empty",
          },

          {
            label: "Site Zone Status",
            name: "isActive",
            customRender: ActiveCell,
            editable: true,
          },
        ];
      case "Member plans":
        return [
          {
            label: "Title",
            name: "title",
            headTextLeft: true,
            textLeft: true,
            editable: true,
            checkable: true,
            helperText: "not empty",
          },

          {
            label: "Price",
            name: "price",
            editable: true,
            checkable: true,
            helperText: "not empty",
          },
          {
            label: "Duration",
            name: "duration",
            editable: true,
            number: true,
            checkable: true,
            helperText: "not empty",
          },
          {
            label: "Contract Status",
            name: "isActive",
            customRender: ActiveCell,
            editable: true,
          },
        ];
      case "Role":
        return [
          {
            label: "Role",
            name: "roleId",
            headTextLeft: true,
            textLeft: true,
          },

          {
            label: "Rate",
            name: "hourlyRate",
            headTextLeft: true,
            textLeft: true,
            editable: true,
            checkable: true,
            helperText: "Wrong Data",
          },
          {
            label: "Update Date",
            name: "updateDate",
          },
        ];

      case "Template messages":
        return [
          {
            label: "Title",
            name: "title",
            headTextLeft: true,
            textLeft: true,
          },
          {
            label: "Type",
            name: "smsTypeName",
          },
          {
            label: "Content",
            name: "",
            customRender: RenderMess,
            textLeft: true,
          },
          {
            label: "Template Status",
            name: "isActive",
            customRender: ActiveCell,
            editable: true,
          },
        ];
      case "Void Reason":
        return [
          {
            label: "Void Reason",
            name: "reason",
            headTextLeft: true,
            textLeft: true,
            editable: true,
          },
          {
            label: "Reason Status",
            name: "isActive",
            customRender: ActiveCell,
            editable: true,
          },
        ];
      default:
        return;
    }
  };

  const getData = (name) => {
    switch (name) {
      case "Contracts":
        return listContract;

      case "Gates":
        return listGate;

      case "Discounts":
        return listDiscount;

      case "Zones":
        return listZones;

      case "Member plans":
        return listMemberPlan;

      case "Role":
        return listRoleList;
      case "Template messages":
        return listTemplate;
      case "Void Reason":
        return listReason;

      default:
        return;
    }
  };
  const { edit: editContract } = useContract();
  const { edit: editGate } = useGate();
  const { edit: editDiscount } = useDisCount();
  const { edit: editZone } = useZone();
  const { edit: editPlans } = useMemberPlan();
  const handleDetail = (ID) => {
    return navigate(`/sites/SiteDetail/${id}/SiteZoneDetails/${ID}`);
  };
  const handleAdd = (name, ID) => {
    switch (name) {
      case "Contracts":
        return navigate(`/sites/SiteDetail/${id}/AddContract`);

      case "Gates":
        return navigate(`/sites/SiteDetail/${id}/AddGate`);

      case "Discounts":
        return navigate(`/sites/SiteDetail/${id}/AddDiscount`);

      case "Zones":
        return navigate(`/sites/SiteDetail/${id}/AddZone`);

      case "Member plans":
        return navigate(`/sites/SiteDetail/${id}/AddMemberPlan`);

      case "Role":
        return navigate(`/sites/SiteDetail/${id}/AddRole`);
      case "Template messages":
        return navigate(`/sites/SiteDetail/${id}/AddTemplateMessage`);
      case "Void Reason":
        return navigate(`/sites/SiteDetail/${id}/AddVoidReason`);
      default:
        return;
    }
  };
  const handleDelete = async (ID) => {
    switch (selectedCols) {
      case "Contracts":
        {
          try {
            await rc(ID);
          } catch (error) {
            setError({ title: handleErrorResponse(error.status, "Contract") });
          }
        }
        break;
      case "Gates":
        {
          try {
            await removeGate(ID);
          } catch (error) {
            setError({ title: handleErrorResponse(error.status, "Gate") });
          }
        }
        break;
      case "Discounts":
        {
          try {
            await removeDisCount(ID);
          } catch (error) {
            setError({ title: handleErrorResponse(error.status, "Discount") });
          }
        }
        break;
      case "Zones":
        {
          try {
            await removeZone(ID);
          } catch (error) {
            setError({ title: handleErrorResponse(error.status, "Zone") });
          }
        }
        break;
      case "Member plans":
        {
          try {
            await removeMemberPlan(ID);
          } catch (error) {
            setError({
              title: handleErrorResponse(error.status, "Member Plan"),
            });
          }
        }
        break;
      case "Role":
        {
          try {
            await removeRole(id, ID);
          } catch (error) {
            setError({
              title: handleErrorResponse(error.status, "Member Plan"),
            });
          }
        }
        break;
      case "Template messages":
        {
          try {
            await removeTemplate(ID);
          } catch (error) {
            setError({
              title: handleErrorResponse(error.status, "Member Plan"),
            });
          }
        }
        break;
      case "Void Reason":
        {
          try {
            await removeReason(ID);
          } catch (error) {
            setError({
              title: handleErrorResponse(error.status, "Member Plan"),
            });
          }
        }
        break;
      default:
        return;
    }
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleSuccess = () => {
    setOpen(true);
  };
  const handleEdite = async (e, ID) => {
    switch (selectedCols) {
      case "Contracts":
        {
          delete e[0].contractUrl;
          delete e[0].id;

          try {
            await editContract(e[0], ID);
            handleSuccess();
            setreload(true);
            fetchSiteInfo(id);
            setError(null);
          } catch (error) {
            console.log(error, "error");
            // if (error.errors.response === 19)
            //   setError({ title: "Invalid Date" });
            setError({ title: handleErrorResponse(error.status, "Contract") });
            setreload(true);
            fetchSiteInfo(id);
          }
        }
        break;
      case "Gates":
        {
          delete e[0].id;
          delete e[0].typeName;
          try {
            await editGate(e[0], ID);
            handleSuccess();
            setreload(true);
            fetchSiteInfo(id);
            setError(null);
          } catch (error) {
            setError({ title: handleErrorResponse(error.status, "Gate") });
            setreload(true);
            fetchSiteInfo(id);
          }
        }
        break;
      case "Discounts":
        {
          delete e[0].id;
          try {
            await editDiscount(e[0], ID);
            handleSuccess();
            setreload(true);
            fetchSiteInfo(id);
            setError(null);
          } catch (error) {
            setError({ title: handleErrorResponse(error.status, "Discount") });
            setreload(true);
            fetchSiteInfo(id);
          }
        }
        break;
      case "Zones":
        {
          delete e[0].id;
          try {
            await editZone(e[0], ID);
            handleSuccess();
            setreload(true);
            setError(null);
            fetchSiteInfo(id);
          } catch (error) {
            console.log(error, "error");
            setError({ title: handleErrorResponse(error.status, "Zone") });
            setreload(true);
            fetchSiteInfo(id);
          }
        }
        break;
      case "Member plans":
        {
          delete e[0].id;
          if (e[0].price < 0 || e[0].duration < 0) {
            setError({
              title: "Price and Duration must be equal or greater than 0",
            });
            setreload(true);
          } else {
            try {
              await editPlans(e[0], ID);
              handleSuccess();
              setValid(true);
              setreload(true);
              fetchSiteInfo(id);
              setError(null);
            } catch (error) {
              setError({
                title: handleErrorResponse(error.status, "Member Plan"),
              });
              setreload(true);
              fetchSiteInfo(id);
            }
          }
        }

        break;

      case "Role": {
        let sid;
        delete e[0].role;
        if (!sid) sid = e[0].siteId;

        try {
          await updateRole(e[0], sid);
          handleSuccess();
          setValid(true);
          setreload(true);
          fetchSiteInfo(id);
          setError(null);
        } catch (error) {
          setError({
            title: handleErrorResponse(error.status, "Member Plan"),
          });
          setreload(true);
          fetchSiteInfo(id);
        }
        break;
      }
      case "Void Reason": {
        delete e[0].id;
        try {
          await editReason(e[0], ID);
          handleSuccess();
          setValid(true);
          setreload(true);
          fetchSiteInfo(id);

          setError(null);
        } catch (error) {
          setError({
            title: handleErrorResponse(error.status, "Member Plan"),
          });
          setreload(true);
          //fetchSiteInfo(id);
          fetchReason(id);
        }
        break;
      }
      case "Template messages":
        return navigate(`/sites/SiteDetail/${e}/update`);
        break;
    }
  };
  //fetch Sites
  useEffect(() => {
    fetchSiteInfo(id);
  }, [id, reload]);

  if (!SiteInfo) return;
  return (
    <>
      <Typography variant="Poster" component="h4">
        Site Details
      </Typography>
      <InfoSite SiteInfo={SiteInfo} reload={reload} setreload={setreload} />

      <div style={{ margin: "30px 20px" }}>
        <TabsList
          tabs={tabs}
          selectedCols={selectedCols}
          setSelectedCols={setSelectedCols}
          dispatch={dispatch}
        ></TabsList>
        {error ? (
          <Alert severity="error" sx={{ m: 3 }}>
            <ErrorsList error={error} />
          </Alert>
        ) : (
          ""
        )}
      </div>

      <CustomTable
        empty={getData(selectedCols)?.length === 0 ? true : false}
        cols={getCols(selectedCols)}
        data={getData(selectedCols)}
        filters={
          (role === "Company" && {
            add: () => handleAdd(selectedCols),
            search: (e) => {
              console.log(e);
            },
          }) ||
          (role === "Admin" && {
            add: () => handleAdd(selectedCols),
            search: (e) => {
              console.log(e);
            },
          }) ||
          (role === "Site" &&
            selectedCols !== "Contracts" && {
              add: () => handleAdd(selectedCols),
              search: (e) => {
                console.log(e);
              },
            })
        }
        actions={
          (role === "Company" && {
            selectedCols: true,
            inTableEdit: selectedCols !== "Template messages" ? true : false,
            edit: handleEdite,
            delete: handleDelete,
            //  check: (v) => v.target.length !== 0,
            vState: vState,
            setVState: setVState,
          }) ||
          (role === "Admin" && {
            selectedCols: true,
            inTableEdit: selectedCols !== "Template messages" ? true : false,
            edit: handleEdite,
            delete: handleDelete,
            //  check: (v) => v.target.length !== 0,
            vState: vState,
            setVState: setVState,
          }) ||
          (role === "Site" &&
            selectedCols !== "Contracts" && {
              selectedCols: true,
              inTableEdit: selectedCols !== "Template messages" ? true : false,
              edit: handleEdite,
              delete: handleDelete,
              //  check: (v) => v.target.length !== 0,
              vState: vState,
              setVState: setVState,
            })
        }
        showButton={true}
        rowsPerPage={10}
        moreOptions={
          selectedCols === "Zones" ? { detail: handleDetail } : false
        }
        title={selectedCols}
        isPagination={false}
      ></CustomTable>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {selectedCols === "SMS Provider" && SiteInfo?.smsProvider && (
          <SMSInfo SMSInfo={SMS} />
        )}
        {selectedCols === "SMS Provider" && !SiteInfo?.smsProvider && (
          <div style={{ textAlign: "center" }}>
            <div style={{ fontSize: "15px" }}>
              No Provider Yet
              {role === "Admin" || role === "Company" ? (
                <Button
                  sx={{
                    background: "white",
                    color: theme.color.blackBackground,
                    border: `1px solid ${theme.color.blackBackground}`,
                    "&:hover": { background: `white` },
                    ml: "10px",
                    textTransform: "capitalize",
                  }}
                  onClick={() => navigate(`add`)}
                >
                  Add +
                </Button>
              ) : (
                ""
              )}
            </div>
          </div>
        )}
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {selectedCols === "Web Content" && SiteInfo?.webContent && (
          <WebContentInfo webContentInfo={SiteInfo?.webContent} />
        )}
        {selectedCols === "Web Content" && !SiteInfo?.webContent && (
          <div style={{ textAlign: "center" }}>
            <div style={{ fontSize: "15px" }}>
              No Web Content Yet
              <Button
                sx={{
                  background: "white",
                  color: theme.color.blackBackground,
                  border: `1px solid ${theme.color.blackBackground}`,
                  "&:hover": { background: `white` },
                  ml: "10px",
                  textTransform: "capitalize",
                }}
                onClick={() => navigate(`addWebContent`)}
              >
                Add +
              </Button>
            </div>
          </div>
        )}
      </div>
      <ModalBox
        open={open}
        operation="edit"
        handleClose={handleClose}
        text="The update operation has been completed successfully.."
        name={`${selectedCols}`}
      />
    </>
  );
};

export default React.memo(DetailSite);
