import { useContext, useReducer, createContext, useCallback } from "react";
import { useRequest } from "../../services/request";

const Context = createContext();

export function useSite() {
  return useContext(Context);
}

const initState = {
  list: [],
  Names: [],
  listSub: [],
  listContract: [],
  listGate: [],
  listDiscount: [],
  listZones: [],
  listMemberPlan: [],
  listRoleList: [],
  listTemplate: [],
  listReason: [],
  SMS: null,
  webContent: null,
  filter: { companyId: 0, subCompanyId: 0 },
  printingDiv1: null,
  printingDiv2: null,
  printingDiv3: null,
  printingDiv4: null,
  selectedTab: "Contracts",
  selected: null,
  Clone: null,
  createdSiteId: 0,
  selectedfile: null,
  selectedSMS: null,
  count: 1,
  page: 1,
  perPage: 10,
  totalSize: 0,
  status: "idle",
  error: null,
  add: false,
};
const reducer = (state, action) => {
  switch (action.type) {
    case "set_list":
      return { ...state, list: [...action.payload] };
    case "set_Names":
      return { ...state, Names: [...action.payload] };
    case "set_listSubCompany":
      return { ...state, listSub: [...action.payload] };
    case "set_listContract":
      return { ...state, listContract: [...action.payload] };
    case "set_listDiscount":
      return { ...state, listDiscount: [...action.payload] };
    case "set_listGate":
      return { ...state, listGate: [...action.payload] };
    case "set_listZone":
      return { ...state, listZones: [...action.payload] };
    case "set_listMemberPlan":
      return { ...state, listMemberPlan: [...action.payload] };
    case "set_listRole":
      return { ...state, listRoleList: [...action.payload] };
    case "set_listTemplate":
      return { ...state, listTemplate: [...action.payload] };
    case "set_listReason":
      return { ...state, listReason: [...action.payload] };
    case "set_selected_tab":
      return { ...state, selectedTab: action.payload };
    case "set_selected":
      return { ...state, selected: { ...action.payload } };
    case "set_selectedSMS":
      return { ...state, selectedSMS: { ...action.payload } };
    case "set_sms":
      return { ...state, SMS: { ...action.payload } };
    case "set_webContent":
      return { ...state, webContent: { ...action.payload } };
    case "set_Clone":
      return { ...state, Clone: { ...action.payload } };
    case "set_createdSiteId":
      return { ...state, createdSiteId: action.payload };
    case "set_count":
      return { ...state, count: action.payload };
    case "set_div1":
      return { ...state, printingDiv1: action.payload };
    case "set_div2":
      return { ...state, printingDiv2: action.payload };
    case "set_div3":
      return { ...state, printingDiv3: action.payload };
    case "set_div4":
      return { ...state, printingDiv4: action.payload };
    case "set_filter":
      return { ...state, filter: action.payload };
    case "add":
      const newList = [{ ...action.payload }, ...state.list];
      return { ...state, list: newList };
    case "edit":
      const modified = state.list.map((p) =>
        p.id === action.payload.id ? { ...p, ...action.payload } : p
      );
      return { ...state, list: modified };
    case "delete":
      const filtered = state.list.filter((p) => p.id !== action.payload);
      return { ...state, list: filtered };
    case "deleteContract":
      const filteredc = state.listContract.filter(
        (p) => p.id !== action.payload
      );
      return { ...state, listContract: filteredc };
    case "deleteGate":
      const filteredg = state.listGate.filter((p) => p.id !== action.payload);
      return { ...state, listGate: filteredg };
    case "deleteDiscount":
      const filteredd = state.listDiscount.filter(
        (p) => p.id !== action.payload
      );
      return { ...state, listDiscount: filteredd };
    case "deleteZone":
      const filteredZ = state.listZones.filter((p) => p.id !== action.payload);
      return { ...state, listZones: filteredZ };
    case "deleteMemberPlan":
      const filteredM = state.listMemberPlan.filter(
        (p) => p.id !== action.payload
      );
      return { ...state, listMemberPlan: filteredM };
    case "deleteRole":
      const filteredR = state.listRoleList.filter(
        (p) => p.roleId != action.payload
      );
      return { ...state, listRoleList: filteredR };
    case "deleteTemplate":
      const filteredT = state.listTemplate.filter(
        (p) => p.id != action.payload
      );
      return { ...state, listTemplate: filteredT };
    case "deleteReason":
      const filteredReason = state.listReason.filter(
        (p) => p.id != action.payload
      );
      return { ...state, listReason: filteredReason };
    case "status":
      return { ...state, status: action.payload };
    case "set_totalSize":
      return { ...state, totalSize: action.payload };
    case "set_page":
      return { ...state, page: action.payload };
    case "set_per_page":
      return { ...state, perPage: action.payload };
    case "set_add":
      return { ...state, add: false };
    default:
      throw new Error(`Invalid dispatch type: ${action.type}`);
  }
};

export default function SiteProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initState);
  const req = useRequest();

  const fetchList = useCallback(
    async (cid = 0, page, pageSize = state.perPage, scid = 0, search) => {
      if (pageSize === 0) pageSize = 10;
      let headersData = {
        pageNumber: page,
      };
      if (pageSize) headersData.pageSize = pageSize;
      if (search) headersData.search = search;
      dispatch({ type: "status", payload: "fetching" });
      const resData = await req(
        `Site/List/${cid}, ${scid}`,
        null,
        {},
        true,
        headersData
      );
      dispatch({ type: "status", payload: `idle` });
      dispatch({ type: "set_list", payload: resData.data.sites || [] });
      // dispatch({ type: "set_per_page", payload: resData.data.pageSize });
      dispatch({ type: "set_count", payload: resData.data.totalPages || 1 });
      dispatch({ type: "set_totalSize", payload: resData.data.totalSize || 0 });
    },
    [req, state.page, state.perPage]
  );
  /** fetch all sites without paging */
  const fetchSiteNames = useCallback(
    async (cid = 0, scid = 0) => {
      dispatch({ type: "status", payload: "fetching" });
      const resData = await req(`Site/Names/${cid}, ${scid}`, null, {}, true);
      dispatch({ type: "status", payload: `idle` });
      dispatch({ type: "set_Names", payload: resData.data || [] });
    },
    [req]
  );
  const fetchOneSMS = useCallback(
    async (id) => {
      return new Promise(async (resolve, reject) => {
        try {
          dispatch({ type: "status", payload: `fetching` });
          const resData = await req(
            `Site/SmsProviderInfo/${id}`,
            null,
            {},
            true
          );
          dispatch({ type: "status", payload: `idle` });
          dispatch({ type: "set_selectedSMS", payload: resData.data });
          resolve(resData);
        } catch (error) {
          console.log(error, "error");
          if (error.status === 404) {
            dispatch({
              type: "set_selectedSMS",
              payload: null,
            });
          }
        }
      });
    },
    [req]
  );
  const fetchRoleList = useCallback(
    async (id) => {
      dispatch({ type: "status", payload: "fetching" });
      const resData = await req(`SiteRoleRate/List/${id}`, null, {}, true);
      // console.log(resData, "status");
      dispatch({ type: "status", payload: `idle` });
      dispatch({ type: "set_listRole", payload: resData.data || [] });
      // dispatch({ type: "set_per_page", payload: resData.data.pageSize });
      dispatch({ type: "set_count", payload: resData.data?.length || 1 });
      dispatch({ type: "set_totalSize", payload: resData.data.totalSize || 0 });
    },
    [req, state.page]
  );

  const fetchTicketPrintingFields = useCallback(
    async (id) => {
      dispatch({ type: "status", payload: "fetching" });
      const resData = await req(
        `Site/TicketPrintingFields/${id}`,
        null,
        {},
        true
      );
      dispatch({ type: "status", payload: `idle` });
      dispatch({
        type: "set_div1",
        payload: resData.data[0]?.fields?.map((field) => field?.id) || [],
      });
      dispatch({
        type: "set_div2",
        payload: resData.data[1]?.fields?.map((field) => field?.id) || [],
      });
      dispatch({
        type: "set_div3",
        payload: resData.data[2]?.fields?.map((field) => field?.id) || [],
      });
      dispatch({
        type: "set_div4",
        payload: resData.data[3]?.fields?.map((field) => field?.id) || [],
      });
    },
    [req]
  );

  const fetchOne = useCallback(
    (id) => {
      return new Promise(async (resolve, reject) => {
        dispatch({ type: "status", payload: `fetching` });
        const resData = await req(`Site/Info/${id}`, null, {}, true);
        dispatch({ type: "set_selected", payload: resData.data });
        dispatch({
          type: "set_listContract",
          payload: resData.data.contracts || [],
        });
        dispatch({
          type: "set_listGate",
          payload: resData.data.gates || [],
        });
        dispatch({
          type: "set_listDiscount",
          payload: resData.data.discounts || [],
        });
        dispatch({
          type: "set_listZone",
          payload: resData.data.zones || [],
        });
        dispatch({
          type: "set_listMemberPlan",
          payload: resData.data.memberPlans || [],
        });
        dispatch({
          type: "set_listTemplate",
          payload: resData.data.messageTemplates || [],
        });
        dispatch({
          type: "set_sms",
          payload: resData.data.smsProvider,
        });
        dispatch({
          type: "set_webContent",
          payload: resData.data.webContent,
        });
        dispatch({
          type: "set_listReason",
          payload: resData.data.voidReasons || [],
        });
        dispatch({
          type: "set_listRole",
          payload: resData.data.roleRates || [],
        });
        dispatch({ type: "status", payload: `idle` });
        resolve(resData);
      });
    },
    [req]
  );
  const create = useCallback(
    async (data) => {
      return new Promise(async (resolve, reject) => {
        const newData = { ...data };
        try {
          const resData = await req(
            "Site/Create",
            newData,
            { method: "POST" },
            true
          );
          dispatch({ type: "add", payload: resData.data });
          dispatch({ type: "set_createdSiteId", payload: resData.data.id });
          dispatch({ type: "status", payload: `idle` });
          resolve(resData);
        } catch (e) {
          reject(e);
        } finally {
          dispatch({ type: "status", payload: `idle` });
        }
      });
    },
    [req]
  );

  const updateTicket = useCallback(
    async (id, data) => {
      return new Promise(async (resolve, reject) => {
        try {
          const resData = await req(
            `Site/UpdateTicketPrintingField/${id}`,
            data,
            { method: "PUT" },
            true
          );
          dispatch({ type: "status", payload: `idle` });
          console.log(resData, "reeeeed");
          // dispatch({
          //   type: "set_div1",
          //   payload: resData.data[0]?.fields?.map((field) => field?.id) || [],
          // });
          // dispatch({
          //   type: "set_div2",
          //   payload: resData.data[1]?.fields?.map((field) => field?.id) || [],
          // });
          // dispatch({
          //   type: "set_div3",
          //   payload: resData.data[2]?.fields?.map((field) => field?.id) || [],
          // });
          // dispatch({
          //   type: "set_div4",
          //   payload: resData.data[3]?.fields?.map((field) => field?.id) || [],
          // });
          resolve(resData);
        } catch (e) {
          reject(e);
        } finally {
          dispatch({ type: "status", payload: `idle` });
        }
      });
    },
    [req]
  );

  const resetCounter = useCallback(
    async (id) => {
      return new Promise(async (resolve, reject) => {
        try {
          const resData = await req(
            `Site/ResetCounter/${id}`,
            {},
            { method: "PUT" },
            true
          );
          dispatch({ type: "status", payload: `idle` });
          resolve(resData);
        } catch (e) {
          reject(e);
        } finally {
          dispatch({ type: "status", payload: `idle` });
        }
      });
    },
    [req]
  );
  const updateRole = useCallback(
    async (data, id) => {
      return new Promise(async (resolve, reject) => {
        const newData = { ...data };
        try {
          const resData = await req(
            `SiteRoleRate/Update/${id}`,
            newData,
            { method: "PUT" },
            true
          );
          dispatch({ type: "status", payload: `idle` });
          resolve(resData);
        } catch (e) {
          reject(e);
        } finally {
          dispatch({ type: "status", payload: `idle` });
        }
      });
    },
    [req]
  );
  const edit = useCallback(
    async (id, data) => {
      return new Promise(async (resolve, reject) => {
        dispatch({ type: "status", payload: `creating` });
        try {
          const resData = await req(
            `Site/Update/${id}`,
            data,
            { method: "PUT" },
            true
          );

          dispatch({ type: "edit", payload: resData });
          resolve(resData);
        } catch (e) {
          reject(e);
        } finally {
          dispatch({ type: "status", payload: `idle` });
        }
      });
    },
    [req]
  );

  const remove = useCallback(
    async (id) => {
      return new Promise(async (resolve, reject) => {
        try {
          const resData = await req(
            `Site/Delete/${id}`,
            null,
            { method: "DELETE" },
            true
          );
          dispatch({ type: "delete", payload: resData.data });
          resolve(resData);
        } catch (e) {
          reject(e);
        } finally {
          dispatch({ type: "status", payload: `idle` });
        }
      });
    },
    [req]
  );

  const removeRole = useCallback(
    async (id, rid) => {
      return new Promise(async (resolve, reject) => {
        try {
          const resData = await req(
            `SiteRoleRate/Delete/${id} , ${rid}`,
            null,
            { method: "DELETE" },
            true
          );
          // console.log(rid , 'id');
          dispatch({ type: "deleteRole", payload: rid });
          resolve(resData);
        } catch (e) {
          reject(e);
        } finally {
          dispatch({ type: "status", payload: `idle` });
        }
      });
    },
    [req]
  );

  const removeContract = useCallback(
    async (id) => {
      return new Promise(async (resolve, reject) => {
        try {
          const resData = await req(
            `SiteContract/Delete/${id}/`,
            {},
            { method: "DELETE" },
            true
          );
          dispatch({ type: "status", payload: "idle" });
          dispatch({ type: "deleteContract", payload: id });
          resolve(resData);
        } catch (error) {
          reject(error);
        }
      });
    },
    [req]
  );
  const removeDisCount = useCallback(
    async (id) => {
      return new Promise(async (resolve, reject) => {
        try {
          const resData = await req(
            `SiteDiscount/Delete/${id}/`,
            {},
            { method: "DELETE" },
            true
          );
          dispatch({ type: "status", payload: "idle" });
          dispatch({ type: "deleteDiscount", payload: id });
          resolve(resData);
        } catch (error) {
          reject(error);
        }
      });
    },
    [req]
  );
  const removeGate = useCallback(
    async (id) => {
      return new Promise(async (resolve, reject) => {
        try {
          const resData = await req(
            `SiteGate/Delete/${id}/`,
            {},
            { method: "DELETE" },
            true
          );
          dispatch({ type: "status", payload: "idle" });
          dispatch({ type: "deleteGate", payload: id });
          resolve(resData);
        } catch (error) {
          reject(error);
        }
      });
    },
    [req]
  );
  const removeMemberPlan = useCallback(
    async (id) => {
      return new Promise(async (resolve, reject) => {
        try {
          const resData = await req(
            `SiteMemberPlan/Delete/${id}/`,
            {},
            { method: "DELETE" },
            true
          );
          dispatch({ type: "status", payload: "idle" });
          dispatch({ type: "deleteMemberPlan", payload: id });
          resolve(resData);
        } catch (error) {
          reject(error);
        }
      });
    },
    [req]
  );
  const removeZone = useCallback(
    async (id) => {
      return new Promise(async (resolve, reject) => {
        try {
          const resData = await req(
            `SiteZone/Delete/${id}/`,
            {},
            { method: "DELETE" },
            true
          );
          dispatch({ type: "status", payload: "idle" });
          dispatch({ type: "deleteZone", payload: id });
          resolve(resData);
        } catch (error) {
          reject(error);
        }
      });
    },
    [req]
  );
  const removeTemplate = useCallback(
    async (id) => {
      return new Promise(async (resolve, reject) => {
        try {
          const resData = await req(
            `SiteMessageTemplate/Delete/${id}/`,
            {},
            { method: "DELETE" },
            true
          );
          dispatch({ type: "status", payload: "idle" });
          dispatch({ type: "deleteTemplate", payload: id });
          resolve(resData);
        } catch (error) {
          reject(error);
        }
      });
    },
    [req]
  );
  const removeReason = useCallback(
    async (id) => {
      return new Promise(async (resolve, reject) => {
        try {
          const resData = await req(
            `SiteVoidReason/Delete/${id}/`,
            {},
            { method: "DELETE" },
            true
          );
          dispatch({ type: "status", payload: "idle" });
          dispatch({ type: "deleteReason", payload: id });
          resolve(resData);
        } catch (error) {
          reject(error);
        }
      });
    },
    [req]
  );
  const siteClone = useCallback(
    (id) => {
      return new Promise(async (resolve, reject) => {
        dispatch({ type: "status", payload: `fetching` });
        const resData = await req(`Site/Clone/${id}`, null, {}, true);
        dispatch({ type: "set_Clone", payload: resData.data });
        dispatch({
          type: "set_listContract",
          payload: resData.data.contracts || [],
        });
        dispatch({
          type: "set_listGate",
          payload: resData.data.gates || [],
        });
        dispatch({
          type: "set_listDiscount",
          payload: resData.data.discounts || [],
        });
        dispatch({
          type: "set_listZone",
          payload: resData.data.zones || [],
        });
        dispatch({
          type: "set_listMemberPlan",
          payload: resData.data.memberPlans || [],
        });
        dispatch({
          type: "set_listTemplate",
          payload: resData.data.messageTemplates || [],
        });
        dispatch({
          type: "set_listReason",
          payload: resData.data.voidReasons || [],
        });
        dispatch({ type: "status", payload: `idle` });
        resolve(resData);
      });
    },
    [req]
  );
  return (
    <Context.Provider
      value={{
        state,
        fetchList,
        fetchSiteNames,
        fetchOne,
        edit,
        create,
        resetCounter,
        updateRole,
        remove,
        dispatch,
        removeContract,
        removeDisCount,
        removeGate,
        removeMemberPlan,
        removeZone,
        removeReason,
        removeTemplate,
        removeRole,
        fetchRoleList,
        fetchTicketPrintingFields,
        updateTicket,
        siteClone,
        fetchOneSMS,
      }}
    >
      {children}
    </Context.Provider>
  );
}
