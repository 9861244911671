const Filter = [
  {
    name: "cid",
    label: "Company Name",
    required: true,
    select: true,
    validationType: "number",
    getData: "companyName",
    data: "name",

    validations: [
      {
        type: "required",
        params: ["this field is required"],
      },
    ],
  },
  {
    name: "sid",
    label: "Site Name",

    select: true,
    validationType: "number",
    getData: "siteName",
    data: "name",
  },
  {
    name: "printTimes",
    label: "Print Times",
    number: true,
    validationType: "number",
  },
  {
    name: "fromDate",
    label: "From",
    validationType: "string",
    date: true,
  },
  {
    name: "toDate",
    label: "To",
    validationType: "string",
    date: true,
  },
];
export default Filter;
